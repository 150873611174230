<template>
  <div id="historyAircraft" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">History Aircraft</div>
      </div>
    </div>
    <div id="dataMap" class="wrap-Main">
      <div class="box-S1">
        <div class="box-S4 noPadding">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="date" class="hideMessage" append-icon="mdi-calendar-range" label="Select Date" readonly dense v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" @change="renderHistory()" @input="menu = false"></v-date-picker>
          </v-menu>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <div v-for="item in itemslist" :key="item">
              <div class="group-data-flight">
                <div class="space-div">
                  <div>
                    <span class="size14 bold">Flight No. :&nbsp;</span>
                    <span class="size14 redtext">{{item.FlightNo}}</span>
                  </div>
                  <div>
                    <span class="size14 bold">Flight Time :&nbsp;</span>
                    <span class="size14 redtext">{{ item.DepartureDate }}</span>
                  </div>
                  <div>
                    <span class="size14 bold">Aircraft :&nbsp;</span>
                    <span class="size14 redtext">{{ item.aircrafts.Name }}</span>
                  </div>
                </div>
                <v-divider></v-divider>

                <div
                  class="subtitle-1 font-weight-medium group-moredetail"
                  @click="renderline(item.aircrafts.UnitName,item)"
                >
                  <span class="more-detail size14 bold">More Details</span>
                  <span class="right-arrow">
                    <!-- <img src="../../public/img/detail-arrow.svg" height="24" width="9"/>  -->
                  </span>
                </div>
              </div>
            </div>
            <div v-if="itemslist.length == 0"><div class="t-nodata">No Data</div></div>
          </div>
        </div>
      </div>
      <div class="box-S3">
        <l-map :zoom="zoom" :center="center" style="z-index: 0;height: 75vh;">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            v-for="(item, index) in markersnow"
            :key="'marker-' + index"
            :lat-lng="item.location"
          >
            <l-icon :icon-url="item.iconcheckpoint" :icon-size="[60, 60]" :icon-anchor="[30, 30]"></l-icon>
            <l-popup ref="popContent">{{item.name}}</l-popup>
          </l-marker>
          <l-marker
            v-for="(item, index) in markers"
            :key="'marker-' + index"
            :lat-lng="item.location"
          >
            <l-icon :icon-url="item.iconcheckpoint" :icon-size="[32, 32]" :icon-anchor="[16, 16]"></l-icon>
            <l-popup ref="popContent">{{item.name}}</l-popup>
          </l-marker>

          <l-polyline :lat-lngs="latlngs" color="#0BCC01"></l-polyline>
        </l-map>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LIcon,
  LPopup
} from "vue2-leaflet";
import { startOfDay, endOfDay, format, addHours, addMinutes } from "date-fns";
import feathersClientSkytrack from "../plugins/feathers-client-skytrack";
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      zoom: 8,
      center: L.latLng(7.1874908, 100.6022255),
       url:"http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      markersnow: [],

     // markpoint: "http://203.151.121.245:9062/images/helicopter.png",
       markpoint: "https://api.prodigyfly.com/uploads/helicopter_new.png",
      latlngs: [],
      dataselect: [],
      dataraw: [],
      selectolicenseplate: "นข4557 สงขลา",
      itemslist: []
    };
  },
  async mounted() {
    this.renderHistory();
    this.renderCheckpoint();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderHistory() {
      try {
        var userlogin = JSON.parse(localStorage.getItem("user"));
        console.log(JSON.stringify(userlogin))
        var owneruser = userlogin.Owner;
        if (owneruser == "UOA") {
          owneruser = null;
        } else if (owneruser != "UOA") {
          owneruser = owneruser;
        }
        var dayselect = this.date.substr(8, 2);
        var monthselect = this.date.substr(5, 2);
        var yearselect = this.date.substr(0, 4);
        const q = {
          day: dayselect,
          month: monthselect,
          year: yearselect,
          owner: owneruser
        };
        var res = await feathersClientUOA
          .service("getlistflight")
          .find({ query: q });
        this.itemslist = res[0].data[0];
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถติดต่อ server ได้" + error);
      }
    },

    async GotoDetail(unitname, dataitem) {
      this.renderline(unitname, dataitem);
    },
    async renderCheckpoint() {
      try {
        const q = {};

        var res = await feathersClientSkytrack
          .service("checkpoint")
          .find({ query: q });

        res[0].MapData.forEach(element => {
          let markpoint = null;
          if (element.type === "Waypoint") {
            markpoint = "https://api.prodigyfly.com/uploads/waypoint.png";
          } else if (element.type === "Fixed Installation") {
            markpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Airport") {
            markpoint = "https://api.prodigyfly.com/uploads/airport.svg";
          } else if (element.type === "Vessel") {
            markpoint = "https://api.prodigyfly.com/uploads/vessel.png";
          } else if (element.type === "Semi-Submersible") {
            markpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Jackup") {
            markpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          }

          var c = {};
          c["location"] = eval(
            "L.latLng(" +
              element.position.lat +
              "," +
              element.position.lng +
              ")"
          );
          c["iconcheckpoint"] = markpoint;
          c["name"] = element.name;
          this.markers.push(c);
        });
      } catch (err) {
        this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
      }
    },

    async renderline(unitname, dataitem) {
      this.menu = false;
      if (unitname == null) {
        unitname = "";
      }

      try {
        var datestart = new Date(dataitem.DepartureDate);
        var dateend = new Date(dataitem.ArrivalDate);
        const q = {
          a_description: unitname,
          a_name: dataitem.aircrafts.Name,
          // t_posntime: {
          //   $gt: format(addHours(addMinutes(datestart, -10), 7), "yyyy-MM-dd HH:mm:ss"),
          //   $lt: format(addHours(addMinutes(dateend, 10), 7), "yyyy-MM-dd HH:mm:ss")
          // },          
          $sort: {
            t_posntime: -1
          }
        };
        var res = await feathersClientSkytrack
          .service("tracking")
          .find({ query: q });
        this.latlngs = [];
        res.data.forEach(element => {
          this.latlngs.push(
            eval("[" + element.t_latitude + "," + element.t_longitude + "]")
          );
        });
        this.markersnow = [];
        var c = {};
        c["location"] = eval(
          "L.latLng(" +
            res.data[0].t_latitude +
            "," +
            res.data[0].t_longitude +
            ")"
        );
        c["iconcheckpoint"] = this.markpoint;
        c["name"] = res.data[0].a_name;
        this.markersnow.push(c);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>


<style>
hr {
  width: 150px;
}
</style>